<template>
  <v-container fluid>

    <template>
      <div style="background-color: rgb(118 126 137);">
        <v-breadcrumbs :items="breadcrumbs">
            <template v-slot:divider>
                <v-icon>mdi-forward</v-icon>
            </template>
        </v-breadcrumbs>
      </div>
    </template>
  
    <v-row class="mt-2">
      <v-col cols="12">
        <v-card dark outlined>
            
            <v-card-title class="secondary py-1 text-subtitle-1">
                <v-icon left dense> mdi-flag </v-icon>
                Stall Configuration
                <v-spacer/>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" lazy-validation>
                   
                    <div class="mt-5">
                        <label><B>Event Name :- </B> {{ selectedEvent.EventName ? selectedEvent.EventName : 'N/A'}}</label>
                    </div>

                    <div class="mt-5">
                        <label><B>StallHolder Name :- </B> {{ selectStallHolder ? selectStallHolder : 'N/A'}}</label>
                    </div>

                    <v-text-field
                        v-model="editedItem.StallName"
                        label="Stall Name"
                        :rules="stallNameRules"
                        required
                    ></v-text-field>

                    <v-row class="mb-2">
                      <v-col cols="6">
                        <label>Return Home After Adding Products</label>
                        <v-combobox
                            v-model="editedItem.AddButtonReturnHome"
                            :items="AddButtonReturnHomeTypes"
                            item-text="name"
                            :return-object="false"
                            required
                        ></v-combobox>
                      </v-col>
                    </v-row>

                    <v-row class="mb-2">
                      <v-col cols="6">
                        <label>Order Numbers</label>
                        <v-combobox
                            v-model="editedItem.OrderNumbers"
                            :items="OrderNumberTypes"
                            item-text="name"
                            :return-object="false"
                            required
                        ></v-combobox>
                      </v-col>
                      <v-col cols="5" offset-md="1" style="margin-top: 45px; font-size: 18px;">
                          <span>Inherit Stallholder Settings</span>
                      </v-col>
                    </v-row>

                    <v-row class="mb-2">
                              
                        <v-col cols="6">
                        <v-text-field
                            v-model="editedItem.Abn"
                            label="Abn"
                            :rules="[v => !!v || 'Abn is required']"
                            required
                            :disabled="isSelectABN"
                        ></v-text-field>
                        </v-col>

                        <v-col cols="5" offset-md="1">
                          <v-checkbox
                              v-model="isSelectABN"
                              :label="eventSettings.Abn"
                              class="mr-5"
                              style="padding-top: 12px;"
                          ></v-checkbox>
                        </v-col>

                    </v-row>

                    <v-row class="mb-2">

                      <v-col cols="6">
                        <label>Payment Profile</label>
                        <v-row class="ml-1">
                            <v-checkbox
                                v-model="editedItem.PaymentProfile"
                                label="Cash"
                                value="Cash"
                                class="mr-5"
                                :disabled="isSelectPaymentProfile"                            
                            ></v-checkbox>
                            <v-checkbox
                                v-model="editedItem.PaymentProfile"
                                label="Card"
                                value="Card"
                                class="mr-5"
                                :disabled="isSelectPaymentProfile"                               
                            ></v-checkbox>
                            <v-checkbox
                                v-model="editedItem.PaymentProfile"
                                label="Voucher"
                                value="Voucher"
                                :disabled="isSelectPaymentProfile"                               
                            ></v-checkbox>
                        </v-row>
                      </v-col>

                      <v-col cols="5" offset-md="1">
                        <v-checkbox
                            v-model="isSelectPaymentProfile"
                            :label="eventSettings.PaymentProfile"
                            class="mr-5"
                            style="padding-top: 12px;"
                        ></v-checkbox>
                      </v-col>
                      
                    </v-row>
                        
                    <label>Printing Profile</label>
                    <v-row class="ml-1">

                        <v-col cols="6">
                        <v-row>
                            <div v-for="item in PrintingList" :key="item.id">
                            <v-checkbox
                                v-model="editedItem.PrintingProfile"
                                :label="item.name"
                                :value="item.name"
                                class="mr-5"
                                :rules="eventPrintRules"
                                required
                                :disabled="isSelectPrintingProfile"
                            ></v-checkbox>
                            </div>
                        </v-row>
                        </v-col>

                        <v-col cols="5" offset-md="1">
                        <v-checkbox
                            v-model="isSelectPrintingProfile"
                            :label="eventSettings.PrintingProfile"
                            class="mr-5"
                            style="margin-top: 4px; margin-left: -8px"
                        ></v-checkbox>
                        </v-col>

                    </v-row>

                    <v-row>

                      <v-col cols="6">
                        <v-combobox
                            v-model="editedItem.StandardDrinkLimitEnforce"
                            :items="EnforceTypes"
                            item-text="name"
                            label="Drink Limit Profile"
                            :return-object="false"
                            required
                        ></v-combobox>
                      </v-col>
                      <v-col cols="3" offset-md="1">
                        <v-checkbox
                            v-model="isSelectStandardDrinkLimitEnforce"
                            :label="eventSettings.StandardDrinkLimitEnforce?.toString() || ''"
                            class="mr-5"
                        ></v-checkbox>
                      </v-col>

                    </v-row>

                    <v-row>

                      <v-col cols="6">
                        <v-text-field
                            v-model="editedItem.StandardDrinkLimit"
                            label="Maximum Drink Limit"
                            type="number"
                            min="0"
                            required
                            :disabled="isSelectStandardDrinkLimit"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3" offset-md="1">
                        <v-checkbox
                            v-model="isSelectStandardDrinkLimit"
                            :label="eventSettings.StandardDrinkLimit?.toString() || ''"
                            class="mr-5"
                        ></v-checkbox>
                      </v-col>

                      <v-col cols="2">
                        <v-btn
                          small
                          dark
                          style="margin-top: 25px;"
                        >
                          <v-icon left x-small>
                            mdi-calendar-clock
                          </v-icon>
                          Schedule
                        </v-btn>
                      </v-col>

                    </v-row>

                    <v-row>

                      <v-col cols="6">
                        <v-text-field
                            v-model="editedItem.CardSurchargePercentage"
                            label="Card Surcharge Percentage"
                            type="number"
                            min="0"
                            required
                            :disabled="isSelectCardSurchargePercentage"
                        ></v-text-field>
                        </v-col>

                        <v-col cols="5" offset-md="1">
                        <v-checkbox
                            v-model="isSelectCardSurchargePercentage"
                            :label="eventSettings.CardSurchargePercentage?.toString() || ''"
                            class="mr-5"
                        ></v-checkbox>
                      </v-col>

                    </v-row>
                    
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                            v-model="editedItem.ManagerPin"
                            label="Manager Pin"
                            type="number"
                            min="0"
                            required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="haddleClickCancel()"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            @click="submitForm"
                        >
                            Submit
                        </v-btn>
                    </v-card-actions>

                </v-form>
            </v-card-text>

        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>
<script>
    import 'izitoast/dist/css/iziToast.min.css'; // loading css 
    import iziToast from 'izitoast/dist/js/iziToast.min.js';  // you have access to iziToast now
    import { mapActions, mapState } from 'vuex';
    export default {
      data: () => ({
        breadcrumbs: [
            {
                text: 'Stall',
                disabled: false,
                href: '/StallholdersStallsStaffTerminals',
            },
            {
                text: 'Stall Configuration',
                disabled: true,
                href: '',
            },
        ],
        Action: '',
        editedItem: {
            EventId: '',
            StallholderId: '',
            StallName: '',
            StandardDrinkLimit: '',
            StandardDrinkLimitEnforce: 'Prompt',
            PaymentProfile: [],
            AddButtonReturnHome: 'Yes',
            PrintingProfile: '',
            OrderNumbers: 'Yes',
            CardSurchargePercentage: '',
            ManagerPin: '',
            Abn: '',
            AbnInherit: true,
            PaymentProfileInherit: true,
            PrintingProfileInherit: true,
            CardSurchargePercentageInherit: true,
            StandardDrinkLimitInherit: true,
            StandardDrinkLimitEnforceInherit: true

        },
        EnforceTypes: [
            { id: true, name: 'Enforce'},
            { id: false, name: 'Prompt'}
        ],
        AddButtonReturnHomeTypes: [
            { id: true, name: 'Yes'},
            { id: false, name: 'No'}
        ],
        OrderNumberTypes: [
            { id: true, name: 'Yes'},
            { id: false, name: 'No'}
        ],
        PrintingList: [
            {
                id: 1,
                name: "Print"
            },
            {
                id: 2,
                name: "Prompt"
            },
            {
                id: 3,
                name: "Bypass"
            }
        ],
        stallHolders: [],
        selectStallHolder: '',
        selectedStallHolderObj: {},
        ActionList: ['create', 'update', 'delete'],
        stallNameRules: [
            v => !!v || 'Event Name is required',
        ],
        eventPrintRules: [
            v => !!v || 'Printing Profile is required',
        ],
        eventPercentageRules: [
            v => (v && v > 0) || 'Card Surcharge Percentage must be greater than 0'
        ],

        CSPercentageRules: [
            v => (v && v > 0) || 'Card Surcharge Percentage must be greater than 0'
        ],

        eventSettings: {
            Abn: '',
            PaymentProfile: '',
            PrintingProfile: '',
            StandardDrinkLimit: '',
            StandardDrinkLimitEnforce: '',
            CardSurchargePercentage: ''
        },

        isSelectABN: false,
        isSelectPaymentProfile: false,
        isSelectPrintingProfile: false,
        isSelectStandardDrinkLimit: false,
        isSelectStandardDrinkLimitEnforce: false,
        isSelectCardSurchargePercentage: false
      }),

      updated: function () {
        this.eventSettings.Abn = this.selectedStallHolderObj.Abn;
        this.eventSettings.PaymentProfile = this.selectedStallHolderObj.PaymentProfile !== undefined ? this.selectedStallHolderObj.PaymentProfile.join(', ') : '';
        this.eventSettings.PrintingProfile = this.selectedStallHolderObj.PrintingProfile;
        this.eventSettings.StandardDrinkLimit = this.selectedStallHolderObj.StandardDrinkLimit;
        this.eventSettings.StandardDrinkLimitEnforce = this.selectedStallHolderObj.StandardDrinkLimitEnforce;
        this.eventSettings.CardSurchargePercentage = this.selectedStallHolderObj.CardSurchargePercentage;
      },

      computed: {

        ...mapState('filter', ['globalEventFilter',
        // 'allEvents',
        'allStallHolders']),
        
        selectedEvent: {
          get() {
            return this.globalEventFilter
          },
          set(value) {
            console.log("EVENT IS CHANGED MAKE API REQUEST 111", value)
          }
        },

        // events() {
        //   return this.allEvents.map((obj) => {
        //     return {text: obj.EventName, value: obj.EventId}
        //   });
        // },
        paymentProfileRules() {
            return [
                this.editedItem.PaymentProfile.length > 0 || "At least one item should be selected"
            ];
        }

      },


      watch: {
        selectedEvent (val) {
            this.haddleChangeEvent(val);
        },

        isSelectABN(val) {
          if (val !== null && val === true) {
            this.editedItem.Abn = this.eventSettings.Abn;
            this.editedItem.AbnInherit = val;

            this.test = JSON.parse(localStorage.getItem('setStallObj'))

          } else {
            this.editedItem.Abn = '';
            this.editedItem.AbnInherit = false
          }
        },

        isSelectPaymentProfile (val) {
            if (val !== null && val === true) {
              var str = this.eventSettings.PaymentProfile;
              str = str.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                return letter.toUpperCase();
              });
              this.editedItem.PaymentProfile = str.split(", ");
              this.editedItem.PaymentProfileInherit = val;
            } else {
              this.editedItem.PaymentProfile = '';
              this.editedItem.PaymentProfileInherit = false
            }
        },
        
        isSelectPrintingProfile (val) {
          if (val !== null && val === true) {
            this.editedItem.PrintingProfile = this.eventSettings.PrintingProfile;
            this.editedItem.PrintingProfileInherit = val;
          } else {
            this.editedItem.PrintingProfile = '';
            this.editedItem.PrintingProfileInherit = false
          }
        },

        isSelectStandardDrinkLimit (val) {
          if (val !== null && val === true) {
            this.editedItem.StandardDrinkLimit = this.eventSettings.StandardDrinkLimit;
            this.editedItem.StandardDrinkLimitInherit = val;
          } else {
            this.editedItem.StandardDrinkLimit = '';
            this.editedItem.StandardDrinkLimitInherit = false
          }
        },
        
        isSelectStandardDrinkLimitEnforce (val) {
          if (val !== null && val === true) {
            this.editedItem.StandardDrinkLimitEnforce = this.eventSettings.StandardDrinkLimitEnforce;
            this.editedItem.StandardDrinkLimitEnforceInherit = val;
          } else {
            this.editedItem.StandardDrinkLimitEnforce = '';
            this.editedItem.StandardDrinkLimitEnforceInherit = false
          }
        },
        isSelectCardSurchargePercentage (val) {
          if (val !== null && val === true) {
            this.editedItem.CardSurchargePercentage = this.eventSettings.CardSurchargePercentage;
            this.editedItem.CardSurchargePercentageInherit = val;
          } else {
            this.editedItem.CardSurchargePercentage = '';
            this.editedItem.CardSurchargePercentageInherit = false
          }
        }
      },
      created() {
        if (this.$route.params.id) {

            let eventObj = JSON.parse(localStorage.getItem('setStallObj'));
            this.Action = 'update';
            
            this.editedItem = eventObj;
            this.editedItem.StandardDrinkLimitEnforce = this.editedItem.StandardDrinkLimitEnforce === true ? 'Enforce' : 'Prompt';
            this.editedItem.OrderNumbers = this.editedItem.OrderNumbers === true ? 'Yes' : 'No';
            this.editedItem.AddButtonReturnHome = this.editedItem.AddButtonReturnHome === true ? 'Yes' : 'No';
            this.editedItem.AbnInherit = this.isSelectABN;
            this.editedItem.PaymentProfileInherit = this.isSelectPaymentProfile;
            this.editedItem.PrintingProfileInherit = this.isSelectPrintingProfile;
            this.editedItem.CardSurchargePercentageInherit = this.isSelectCardSurchargePercentage;
            this.editedItem.StandardDrinkLimitInherit = this.isSelectStandardDrinkLimit;
            this.editedItem.StandardDrinkLimitEnforceInherit = this.isSelectStandardDrinkLimitEnforce;
        } else {
            this.Action = 'create';
        }
        this.halldeStallHolder();
      },
      methods: {
        ...mapActions('admin', ['createEvent']),
        ...mapActions('filter', ['initialLogin']),
        haddleIsSelecters: function () {
            this.Abn = false;
            this.isSelectPaymentProfile = false;
            this.isSelectPrintingProfile = false;
            this.isSelectStandardDrinkLimit = false;
            this.isSelectStandardDrinkLimitEnforce = false;
            this.isSelectCardSurchargePercentage = false;
        },
        halldeStallHolder: function() {
          if (this.selectedEvent.EventName !== undefined) {
            this.haddleChangeEvent(this.selectedEvent);
          }
        },
        // haddleChangeEvent: function(obj) {
        haddleChangeEvent: function() {
          this.stallHolders = [];
          this.editedItem.StallholderId = '';

          // let selectedObj = this.allEvents.filter(element => {
          //   return element.EventId === obj.EventId;
          // });
          // this.stallHolders = selectedObj[0].Stallholders.map((obj) => {
          //   return {text: obj.StallholderName, value: obj.StallholderId}
          // });
          

          this.stallHolders = this.globalEventFilter.Stallholders


          this.selectedStallHolderObj = JSON.parse(localStorage.getItem('setStallHolderselectObj'));
          this.editedItem.StallholderId = this.selectedStallHolderObj.StallholderId;
          this.selectStallHolder = this.selectedStallHolderObj.StallholderName;
          if (this.editedItem.Abn == '') this.editedItem.Abn = this.selectedStallHolderObj.Abn

          this.setStallObj = JSON.parse(localStorage.getItem('setStallObj'))
          
          this.isSelectABN = this.setStallObj.AbnInherit !== '' ? this.setStallObj.AbnInherit : false
          this.isSelectPaymentProfile = this.setStallObj.PaymentProfileInherit !== '' ? this.setStallObj.PaymentProfileInherit : false
          this.isSelectPrintingProfile = this.setStallObj.PrintingProfileInherit !== '' ? this.setStallObj.PrintingProfileInherit : false
          this.isSelectStandardDrinkLimit = this.setStallObj.StandardDrinkLimitInherit !== '' ? this.setStallObj.StandardDrinkLimitInherit : false
          this.isSelectStandardDrinkLimitEnforce = this.setStallObj.StandardDrinkLimitEnforceInherit !== '' ? this.setStallObj.StandardDrinkLimitEnforceInherit : false
          this.isSelectCardSurchargePercentage = this.setStallObj.CardSurchargePercentageInherit !== '' ? this.setStallObj.CardSurchargePercentageInherit : false
      
          this.editedItem.PaymentProfile = this.setStallObj.PaymentProfile;
          this.editedItem.PrintingProfile = this.setStallObj.PrintingProfile;
          this.editedItem.PaymentProfile = this.setStallObj.PaymentProfile;
          this.editedItem.StandardDrinkLimit = this.setStallObj.StandardDrinkLimit;
          this.editedItem.CardSurchargePercentage = this.setStallObj.CardSurchargePercentage;       

          this.eventSettings.Abn = this.selectedStallHolderObj.Abn
          this.eventSettings.PaymentProfile = this.selectedStallHolderObj.PaymentProfile !== undefined ? this.selectedStallHolderObj.PaymentProfile.join(', ') : '';
          this.eventSettings.PrintingProfile = this.selectedStallHolderObj.PrintingProfile;
          this.eventSettings.StandardDrinkLimit = this.selectedStallHolderObj.StandardDrinkLimit;
          this.eventSettings.StandardDrinkLimitEnforce = this.selectedStallHolderObj.StandardDrinkLimitEnforce;
          this.eventSettings.CardSurchargePercentage = this.selectedStallHolderObj.CardSurchargePercentage;

        },
        submitForm: function () {
          try {
            if (this.$refs.form.validate()) {
                this.editedItem.EventId = this.selectedEvent.EventId;
                this.editedItem.StandardDrinkLimitEnforce = this.editedItem.StandardDrinkLimitEnforce === 'Enforce' ? true : false;
                this.editedItem.OrderNumbers = this.editedItem.OrderNumbers === 'Yes' ? true : false;
                this.editedItem.AddButtonReturnHome = this.editedItem.AddButtonReturnHome === 'Yes' ? true : false;
                let event_item = [
                    {
                        Type: "Stall",
                        Action: this.Action,
                        Properties: this.editedItem
                    }
                ]
                
                let submitEvent = {
                    event_items: event_item
                }

                this.createEvent(submitEvent).then(response => {
                  
                    console.log('submitForm response: ',response)

                    iziToast.success({
                        title: 'Stall',
                        message: 'Successfully inserted record!',
                        position: 'topRight'
                    });

                    this.redirectReload();
                    
                }).catch(err => {
                    console.log("Error:  ",err)
                    iziToast.warning({
                        title: 'Stall',
                        message: 'Fail to inserted record!',
                        position: 'topRight'
                    });
                })
            }
          } catch (e) {
            console.log(e)
          }
        },
        haddleClickCancel: function () {
          this.$router.push({ name: 'EventManagement' });
        },
        redirectReload() {
          this.initialLogin().then(response => {
            console.log(response)
          })
          this.$router.push({ name: 'EventManagement' });
        }
      }
    }
</script>